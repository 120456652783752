import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Dialog, Slide, DialogTitle, DialogContent, CardMedia, Tooltip, useMediaQuery, Divider, Chip, IconButton, Grid, Stack, Box, Button, colors, Card, CardHeader, CardContent, TextField, InputLabel } from "@mui/material";
import { useState } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";

import axios from "axios";
import { base_Url } from "../BaseUrl/ApiUrl";
import _ from 'lodash'
import Calendar from "./Calander";
import RegisterForm from "./RegisterForm";

import { IoMdCloseCircleOutline } from "react-icons/io";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Margin } from "@mui/icons-material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { LuCalendarOff } from "react-icons/lu";
import { AiTwotoneClockCircle } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import DocumentSection from "./Documents";
import PaymentPage from "./PaymentPage";
import dayjs from 'dayjs';
import DigitalOcean from "./DigitalOcean";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { MdLocalOffer } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import UploadImage from "./UploadImage";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { googleTagForNewUserCardDetails } from "../googleanalytics/googleanalytics";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});



const NewUserRegister = ({ setRegisterStatus, openStatus, data, finalData, classmode }) => {

  console.log('praveen', classmode && classmode)
  const navigate = useNavigate()

  console.log('finalData >>>123', finalData)
  const [open, setOpen] = useState(false);


  const [CourseInfo, setCourseInfo] = useState([])
  const fetchPackages = async () => {
    try {
      const response = await axios.post(`${base_Url}/checkPackageisExist`, {
        packageid: data
      })
      if (response.data.message) {
        console.log('response', response?.data?.response)
        setCourseInfo(response?.data?.response)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPackages()
  }, [data])


  const [coursedetails, setCoursedetails] = useState([])

  useEffect(() => {
    const formating = finalData?.packages?.map((item) => {
      return {
        productid: item.productid,
        productname: item.productname,
        packageid: item.packageid,
        type: item.producttype,
        addonid: null,
        classmode: classmode,
        locationid: '',
        packageamount: item?.packageamount,
        comboid: finalData?.comboid,
        discountprice: item?.discountprice,
        offer: item?.offer

      }
    })
    setCoursedetails(formating)
  }, [finalData, openStatus])


  console.log('coursedetails', coursedetails)




  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (data) => {
    setActiveStep(data);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));


  // register form state 

  const [formData, setFormData] = useState(
    {
      firstname: "",
      lastname: "",
      phonenumber: "",
      email: "",
      billingaddress: "",
      billingcity: "",
      billingstate: "",
      zipcode: "",
      Dob: '',
      gender: '',
      newcountry: 'United States',
      newstate: 'Georgia'
    }
  )

  // same as prev
  const [selected, setSelected] = useState(false);
  // same as prev


  // road test question state

  const [answers, setAnswers] = useState({
    takenbtwACT: null,
    takenbtwothers: null,
    completedDE: null,
    learnerspermit: null,
    DEproofnumber: '',
    permitnumber: ''
  });


  // documents

  const [docInfo, setDocInfo] = useState([])

  console.log('docInfo', docInfo)


  const fetchDoc = async () => {
    try {
      const response = await axios.post(`${base_Url}/getDocs`, {
        "docfor": 1, // 1-student; 2- instructor; 3- car; 0-none
        "status": 1 // 0-all;1-Active;2-InActive
      })
      if (response.data.message) {
        const initialState = response?.data?.data
          .filter(document => document.req === "required")
          .map(document => ({ doctypeid: document.apptypeid, docname: document.typename, doc: null }));
        setDocInfo(initialState)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDoc()
  }, [finalData])



  // calender function 


  const [selectedCourseInfo, setSelectedCourseInfo] = useState({
    courseid: null,
    type: null,
    combo: null
  })


  console.log('FFFFFFFFFFFFFForm Data', formData)



  const [zones, setZones] = useState([])





  const onClickCalander = (item) => {
    console.log('item', item)
    // 1-combo    2 -single => based on packages length find it is combo or single 
    setSelectedCourseInfo({
      courseid: item.productid,
      type: item.producttype,
      combo: finalData?.packages.length <= 1 ? 2 : 1
    })
    setOpen(true)
  }

  const totalDuration = finalData?.duration?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);
  const totalpackageamount = finalData?.packageamount?.split(',').map(Number).reduce((acc, curr) => acc + curr, 0);

  const handleDrawerClose = () => {

    setAnswers(
      {
        takenbtwACT: null,
        takenbtwothers: null,
        completedDE: null,
        learnerspermit: null,
        DEproofnumber: '',
        permitnumber: ''
      }
    )

    setFormData(
      {
        firstname: "",
        lastname: "",
        phonenumber: "",
        email: "",
        billingaddress: "",
        billingcity: "",
        billingstate: "",
        zipcode: "",
        Dob: '',
        newcountry: 'United States',
        newstate: 'Georgia'
      }
    )

    setSelectedCourseInfo(
      {
        courseid: null,
        type: null,
        combo: null
      }
    )

    setDocInfo([])
    // setCoursedetails([])
    setActiveStep(0)

    setRegisterStatus(false)
  }



  const [loaderopen, setLoaderOpen] = useState(false);


  // payment page
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvvNumber: '',
    cardholderfirstname: "",
    cardholderlastname: "",
    cardzipcode: "",
    billingcountryz: "United States",
    billingstatez: "Georgia",
    billingcityz: "",
    billingaddressz: "",
    billingzipcode: ""
  });

  console.log(`epic payment data 🔥 - `, paymentDetails);


  const onClickSubmit = async () => {



    console.log('answers123', answers?.permitnumber)

    const imageUpload = await UploadImage({ srcData: answers?.permitnumber, folderName: `${answers?.permitnumber?.name} ` });

    console.log('imageUpload ###', imageUpload)

    setLoaderOpen(true)
    console.log('clicked submit ', coursedetails)

    const uploadedImages = await Promise.all(DigitalOcean(docInfo));
    console.log('uploadedimages', uploadedImages);



    const docs = uploadedImages.map((item) => {
      return {
        doctypeid: item?.doctypeid,
        doc: item.s3Link
      }
    })

    const de = coursedetails.map((item) => {
      return {
        "productid": item?.productid,
        "productname": item?.productname,
        "packageid": item?.packageid,
        "addonid": item?.addonid === null ? 0 : item?.addonid,  // the slot choosed
        "takenbtwACT": item?.takenbtwACT,      // 0-yes;1-no
        "takenbtwothers": item?.takenbtwothers,
        "completedDE": item?.completedDE,          // 0-yes;1-no
        "DEproofnumber": item?.DEproofnumber,
        "learnerspermit": item?.learnerspermit,       // 0-yes;1-no
        "permitnumber": !_.isNull(imageUpload) ? imageUpload : 0,
        "classmode": item?.classmode || 2,
        "locationid": item?.locationid || 0,
        "countyid": item?.countyid || 0,
        "amount": item?.offer ? item?.discountprice : item?.packageamount,
        "comboid": item?.comboid
      }
    })


    const requestedData = {
      "licenseno": formData?.licenseno,
      "licensestate": formData?.licensestate,
      "firstname": formData.firstname,
      "lastname": formData.lastname,
      "dob": formData.Dob,
      "unitno": formData.unitNo,
      "gender": formData?.gender,
      "phone": formData.phonenumber,
      "email": formData.email,
      "parentname": formData.parentname,
      "relation": formData.relation,
      "parentemail": formData.parentemail,
      "parentphone": formData.parentphone,
      "address": formData.billingaddress,
      "country": formData?.newcountry,
      "city": formData.newcity,
      "state": formData.newstate,
      "country": formData?.newcountry,
      "zipcode": formData.zipcode,
      // "docs": docs,
      "courses": de,

      "orderdetails": {
        "totalamount": finalData?.finalcost || 0,
        "discount": finalData?.discount,
        "discountamount": (finalData?.finalcost * finalData?.discount) / 100,
        "payamount": finalData?.discountprice
      },

      "paymentdetails": {
        "cardNumber": paymentDetails?.cardNumber ? `${paymentDetails?.cardNumber}` : '',  // credit card
        "expirationDate": paymentDetails?.expiryMonth ? `${paymentDetails?.expiryMonth}${paymentDetails?.expiryYear}` : '',
        "cardCode": paymentDetails?.cvvNumber ? `${paymentDetails?.cvvNumber}` : "",
        "amount": finalData?.discountprice,
        "cardholderfirstname": paymentDetails?.cardholderfirstname ? `${paymentDetails?.cardholderfirstname}` : '',
        "cardholderlastname": paymentDetails?.cardholderlastname ? `${paymentDetails?.cardholderlastname}` : '',
        "cardzipcode": paymentDetails?.cardzipcode ? `${paymentDetails?.cardzipcode}` : '',
        "billingcountry": selected ? formData?.newcountry : paymentDetails?.billingcountryz,
        "billingstate": selected ? formData?.newstate : paymentDetails?.billingstatez,
        "billingcity": selected ? formData?.billingcity : paymentDetails?.billingcityz,
        "billingaddress": selected ? formData?.billingaddress : paymentDetails?.billingaddressz,
        "billingzipcode": selected ? formData?.zipcode : paymentDetails?.cardzipcode
      },
      "roleid": 2,
      "configid": 1,
      "authmode": 1,
      "registervia": 2  // 1- socialmedia; 2- website

    }

    const requestedDataForGTM = {
      "licenseno": formData?.licenseno,
      "licensestate": formData?.licensestate,
      "firstname": formData.firstname,
      "lastname": formData.lastname,
      "dob": formData.Dob,
      "unitno": formData.unitNo,
      "gender": formData?.gender,
      "phone": formData.phonenumber,
      "email": formData.email,
      "parentname": formData.parentname,
      "relation": formData.relation,
      "parentemail": formData.parentemail,
      "parentphone": formData.parentphone,
      "address": formData.billingaddress,
      "country": formData?.newcountry,
      "city": formData.newcity,
      "state": formData.newstate,
      "country": formData?.newcountry,
      "zipcode": formData.zipcode,
      // "docs": docs,
      "courses": de,
      "orderdetails": {
        "totalamount": finalData?.finalcost || 0,
        "discount": finalData?.discount,
        "discountamount": (finalData?.finalcost * finalData?.discount) / 100,
        "payamount": finalData?.discountprice
      }
    }



    // for existing user only

    let epicUrl;
    let epicData;

    const isExisUser = JSON.parse(localStorage.getItem('Student'))

    if (isExisUser) {

      const newOrderDetails = coursedetails?.map((item) => {
        return {
          "productid": item?.productid,
          "packageid": item?.packageid,
          "addonid": item?.addonid === null ? 0 : item?.addonid,  // the slot choosed
          "comboid": item.comboid,
          "amount": item?.offer ? item?.discountprice : item?.packageamount,
        }
      })

      epicUrl = `/buyOtherCourse`
      epicData = {
        customerid: isExisUser?.customerid,
        courses: de,
        orderdetails: newOrderDetails,
        "paymentdetails": {
          "cardNumber": paymentDetails?.cardNumber ? `${paymentDetails?.cardNumber}` : '',  // credit card
          "expirationDate": paymentDetails?.expiryMonth ? `${paymentDetails?.expiryMonth}${paymentDetails?.expiryYear}` : '',
          "cardCode": paymentDetails?.cvvNumber ? `${paymentDetails?.cvvNumber}` : "",
          "amount": finalData?.discountprice,
          "cardholderfirstname": paymentDetails?.cardholderfirstname ? `${paymentDetails?.cardholderfirstname}` : '',
          "cardholderlastname": paymentDetails?.cardholderlastname ? `${paymentDetails?.cardholderlastname}` : '',
          "cardzipcode": paymentDetails?.cardzipcode ? `${paymentDetails?.cardzipcode}` : '',
          "billingcountry": selected ? formData?.newcountry : paymentDetails?.billingcountryz,
          "billingstate": selected ? formData?.newstate : paymentDetails?.billingstatez,
          "billingcity": selected ? formData?.billingcity : paymentDetails?.billingcityz,
          "billingaddress": selected ? formData?.billingaddress : paymentDetails?.billingaddressz,
          "billingzipcode": selected ? formData?.zipcode : paymentDetails?.cardzipcode
        },
        "purchasedetails": {
          "totalamount": finalData?.packages?.reduce((acc, item) => acc + item.packageamount, 0) || 0,
          "discount": finalData?.discount,
          "discountamount": (finalData?.finalcost * finalData?.discount) / 100,
          "payamount": finalData?.comboid == 0 ? finalData?.packages?.find(item => item.packageamount)?.packageamount || 0 : finalData?.discountprice
        }
      }
    }
    else {
      epicUrl = `/studentregister`
      epicData = requestedData
    }


    // for existing user only



    console.log('requestedData', requestedData)


    try {
      const response = await axios.post(`${base_Url}${epicUrl}`, epicData)
      if (response.data.status) {
        setLoaderOpen(false)
        toast.success('Registered Successfully')
        navigate('/checkout', {
          state: {
            data: response?.data?.response
          }
        })
        // 🔍 google tag manager 🔍
        googleTagForNewUserCardDetails(requestedDataForGTM)
        // 🔍 google tag manager 🔍


        // trackPageLoadEvent("purchase",requestedDataForGTM?.purchasedetails?.payamount)
      } else {
        toast.error(response?.data?.message)
        setLoaderOpen(false)
      }
    } catch (error) {
      console.log('error in main api', error)
    }
  }

  const isRoadTest = finalData?.packages?.some((item) => item.producttype === 5);
  console.log('isRoadTest', isRoadTest)


  let steps;
  if (isRoadTest) {
    steps = [
      'Personal Details',
      'Road Test Q/A',
      'Payment',
    ];
  } else {
    steps = [
      'Personal Details',
      'Payment',
    ];
  }



  const getChipcolor = (data) => {
    console.log('item inside chip', data?.packageid)
    const colour = coursedetails?.find(item => item.packageid == data?.packageid)?.addonid
    console.log('colour', colour)
    if (!_.isEmpty(colour)) {
      return 'success'
    } else {
      return 'error'
    }

  }


  const getChipicon = (data) => {
    console.log('item inside chip', data?.packageid)
    const colour = coursedetails?.find(item => item.packageid == data?.packageid)?.addonid
    console.log('colour', colour)
    if (!_.isEmpty(colour)) {
      return 1
    } else {
      return 2
    }

  }

  const isMobileView = useMediaQuery('(max-width:600px)');

  const selectedBTWHour = finalData?.packages?.find(item => item.producttype == 1)?.duration

  console.log('selectedBTWHour inside dialog', selectedBTWHour)





  let isStudentExist
  if (openStatus) {
    isStudentExist = JSON.parse(localStorage.getItem('Student'))
  } else {
    isStudentExist = null
  }

  console.log('isStudentExist >>', isStudentExist)

  useEffect(() => {
    console.log('isStudentExist in sueeffect', isStudentExist)
    if (isStudentExist) {
      fetchUserData()
    }
  }, [openStatus])

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${base_Url}getStudentDataById/${isStudentExist?.customerid}`);
      if (response.data.status === true) {
        console.log('response.data.response', response.data.response[0])
        const data = response.data.response[0]
        setFormData((prev) => ({
          ...prev,
          firstname: data?.firstname,
          lastname: data?.lastname,
          phonenumber: data?.contactno1,
          billingaddress: data?.address,
          zipcode: data?.postcode,
          Dob: data?.dob,
          newcountry: data?.country,
          newstate: data?.state,
          newcity: data?.city,
          email: data?.email
        }))
        // setUserData(response.data.response[0]);
      }
    } catch (error) {
      console.log('getAllClients api status', error);
    }
  };



  const priceHelper = (data1, data2) => {
    return data1 == data2 ? true : false
  }



  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loaderopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
      <Drawer anchor="right" open={openStatus}

        ModalProps={{ BackdropProps: { invisible: true } }}
        PaperProps={{ sx: { width: isSmallScreen ? '100%' : '80%' } }}
      >

        {/* <Dialog maxWidth='xl' fullWidth open={openStatus}  TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            height: '800px', 
            '@media (max-width: 600px)': {
              height: '800px', 
            },
            '@media (min-width: 600px) and (max-width: 960px)': {
              height: '800px',
            },
            '@media (min-width: 960px)': {
              height: '800px',
            },
          },
        }}

      > */}


        <Stack direction='row' justifyContent='space-between' sx={{ p: 1 }} alignItems={'center'}>
          <Typography variant="h5" sx={{ color: 'black !important' }}> Checkout</Typography>
          {/* <Stack direction={'row'} alignItems={'center'}> */}
          <Typography sx={{ display: { xs: 'none !important', sm: 'none !important', md: 'block !important', lg: 'block !important' }, color: '#dc2626 !important', marginLeft: '5px' }}>For any assistance, Please call us at 770-274-4223</Typography>
          <IconButton sx={{ fontSize: '30px', color: 'red' }} variant="outlined" color='error' onClick={() => handleDrawerClose()}  >
            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
          </IconButton>
          {/* </Stack> */}
        </Stack>



        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div >
                <Card sx={{ marginBottom: '20px' }}>
                  <Typography sx={{ p: 1 }}>Courses</Typography>
                </Card>


                {/* new look by madhan */}
                <Card>

                  {finalData?.packages?.length > 1 && (
                    <Stack direction={'row'} justifyContent={'center'} sx={{ marginBottom: '10px' }}>
                      <Chip
                        icon={<BiSolidOffer />}
                        size="small"
                        label={"Combo Offer"}
                        sx={{ mx: 1, mt: 1, p: 1 }}
                        variant="filled"
                        color="success"
                      />
                    </Stack>
                  )}

                  {
                    finalData?.packages?.map((item, index) => {
                      return <Stack sx={{ borderBottom: '1px solid #d4d4d8' }}>
                        <Grid container sx={{ display: 'flex', padding: '10px 20px' }} alignItems={'center'}>

                          <Grid item xs={12} md={6} lg={5}>
                            <Stack alignItems={isMobileView ? "center" : "flex-start"} >
                              <Typography align={isMobileView ? "center" : "left"}>{item.productname}</Typography>
                              {item.producttype !== 5 && (
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                  <AiTwotoneClockCircle />
                                  <Typography>{item.duration} Hrs</Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Grid>

                          <Grid item xs={12} md={6} lg={3.5}
                            //  sx={{ display: 'flex' }}
                            justifyContent={'center'}>
                            <Stack alignItems={isMobileView ? "center" : "start"} spacing={1}>


                              {
                                !item.offer ?
                                  <Typography component="span" sx={{ fontSize: "25px", fontWeight: 'bold', minWidth: "70px" }}>
                                    ${item.packageamount}
                                  </Typography>

                                  :
                                  <>

                                    <Stack alignItems="start" spacing={0.5} >
                                      <Typography component="span" sx={{ fontSize: "25px", fontWeight: 'bold', minWidth: "70px" }}>
                                        ${item.discountprice}
                                      </Typography>
                                      <Typography component="span" sx={{ textDecoration: "line-through", color: "black", minWidth: "70px" }}>
                                        ${item.packageamount}
                                      </Typography>
                                    </Stack>
                                  </>

                              }








                            </Stack>
                          </Grid>


                          <Grid item xs={12} md={6} lg={3.5} sx={{ display: 'flex' }} justifyContent={'center'} >
                            {item?.producttype !== 1 && item?.producttype !== 5 ? (
                              <Chip
                                icon={getChipicon(item) === 1 ? (
                                  <TiTick style={{ width: "20px", height: "20px" }} />
                                ) : (
                                  <FaRegCalendarAlt style={{ width: "20px", height: "20px" }} />
                                )}
                                label={getChipicon(item) === 1 ? "Slot Picked" : "Pick Slot"}
                                variant="outlined"
                                color={getChipcolor(item)}
                                sx={{
                                  padding: "10px 20px",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                  width: isMobileView ? "90%" : "auto",
                                }}
                                onClick={() => onClickCalander(item)}
                              />
                            ) : (
                              <Typography></Typography>
                            )}
                          </Grid>

                        </Grid>
                      </Stack>
                    })
                  }

               

                </Card>
                {/* new look by madhan */}


                {/* old */}
                {/* <Card>

                  {finalData?.packages?.length > 1 && (
                    <Stack direction={'row'} justifyContent={'center'} sx={{ marginBottom: '10px' }}>
                      <Chip
                        icon={<BiSolidOffer />}
                        size="small"
                        label={"Combo Offer"}
                        sx={{ mx: 1, mt: 1, p: 1 }}
                        variant="filled"
                        color="success"
                      />
                    </Stack>
                  )}

                  <Stack spacing={1} justifyContent={'center'} alignItems={'center'} direction={'column'}>
                    {
                      finalData?.packages?.map((item, index) => {
                        return (
                          <Grid container key={index} spacing={1} >

                            <Grid item xs={12} md={6} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                              <div
                                style={{
                                  border: "1px solid #606060", // Slightly darker border
                                  borderRadius: "8px",           // Increased rounding for a premium look
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                                  width: isMobileView ? "100%" : "90px",
                                  height: "55px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: isMobileView ? "0 auto" : "0",
                                }}
                              >
                                <img
                                  src={item?.image}
                                  alt="product"
                                  style={{
                                    objectFit: "contain",
                                    maxWidth: "100%",
                                    height: "100%",
                                  }}
                                />
                              </div>
                            </Grid>

                            <Grid item xs={12} md={6} lg={2} >
                              <Stack alignItems={isMobileView ? "center" : "start"} >
                                {[{ productType: 4, condition: selectedBTWHour >= 6, comboAmountKey: "combopackageamount1" },
                                { productType: 5, condition: selectedBTWHour, comboAmountKey: "combopackageamount1" },
                                { productType: 3, condition: selectedBTWHour < 12, comboAmountKey: "combopackageamount1" },
                                { productType: 3, condition: selectedBTWHour >= 12, comboAmountKey: "combopackageamount2" }
                                ].map(({ productType, condition, comboAmountKey }) =>
                                  item.producttype === productType && finalData?.packages?.length > 1 && condition ? (
                                    <Stack justifyContent="center" alignItems="center" key={comboAmountKey}>
                                      <Typography component="span" sx={{ fontSize: "25px" }}>
                                        <b>${item[comboAmountKey]}</b>
                                      </Typography>
                                      <Typography component="span" sx={{ textDecoration: "line-through", color: "black" }}>
                                        ${item.packageamount}
                                      </Typography>
                                    </Stack>
                                  ) : null
                                )}

                                {(![
                                  { productType: 4, condition: selectedBTWHour >= 6 },
                                  { productType: 5, condition: selectedBTWHour },
                                  { productType: 3, condition: selectedBTWHour < 12 },
                                  { productType: 3, condition: selectedBTWHour >= 12 }
                                ].some(({ productType, condition }) => item.producttype === productType && condition)) && (
                                    <Typography component="span" sx={{ fontSize: "25px" }}>
                                      <b>${item.packageamount}</b>
                                    </Typography>
                                  )}
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={6} lg={4}>
                              <Stack alignItems={isMobileView ? "center" : "flex-start"} >
                                <Typography align={isMobileView ? "center" : "left"}>{item.productname}</Typography>
                                {item.producttype !== 5 && (
                                  <Stack direction="row" spacing={0.5} alignItems="center">
                                    <AiTwotoneClockCircle />
                                    <Typography>{item.duration} Hrs</Typography>
                                  </Stack>
                                )}
                              </Stack>
                            </Grid>

                            <Grid item xs={12} md={6} lg={3} >
                              {item?.producttype !== 1 && item?.producttype !== 5 ? (
                                <Chip
                                  icon={getChipicon(item) === 1 ? (
                                    <TiTick style={{ width: "20px", height: "20px" }} />
                                  ) : (
                                    <FaRegCalendarAlt style={{ width: "20px", height: "20px" }} />
                                  )}
                                  label={getChipicon(item) === 1 ? "Slot Picked" : "Pick Slot"}
                                  variant="outlined"
                                  color={getChipcolor(item)}
                                  sx={{
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    width: isMobileView ? "90%" : "auto",  // Make button full-width on mobile
                                  }}
                                  onClick={() => onClickCalander(item)}
                                />
                              ) : (
                                <Typography></Typography>
                              )}
                            </Grid>

                          </Grid>
                        )
                      })
                    }
                  </Stack>


                </Card> */}
                {/* old */}

                {
                  finalData?.comboid != 0 && <>
                    <Card sx={{ marginTop: '20px', display: { xs: 'block', md: 'none' }, padding: '20px' }}> {/* mobile devices */}

                      {
                        priceHelper(Math.round(finalData?.discountprice), finalData?.finalcost) ? <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>

                          <Typography variant="h6" sx={{ color: '#a1a1aa !important', marginBottom: '10px' }} align={isMobileView ? "center" : "left"}>Total Price</Typography>
                          <Typography sx={{ marginBottom: '10px' }}>

                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${finalData?.packages?.reduce((total, item) => total + (Number(item.packageamount) || 0), 0)}
                                </span> :
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${Math.round(finalData?.discountprice)}
                                </span>
                            }
                          </Typography>

                        </Stack> : <>
                          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>

                            <Typography variant="h6" sx={{ color: '#a1a1aa !important' }} align={isMobileView ? "center" : "left"}>Actual Price</Typography>

                            <Typography
                              component="span"
                              sx={{ textDecoration: 'line-through', color: 'black' }}
                            >


                              {
                                finalData?.comboid == 0 ?
                                  <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                  </span> :
                                  <span

                                  >
                                    ${finalData?.finalcost}
                                  </span>
                              }

                            </Typography>

                          </Stack>

                          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>

                            <Typography variant="h6" sx={{ color: '#a1a1aa !important' }} align={isMobileView ? "center" : "left"}>Discounted Price</Typography>

                            <Typography >
                              {
                                finalData?.comboid == 0 ?
                                  <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                    ${finalData?.packages?.reduce((total, item) => total + (Number(item.packageamount) || 0), 0)}
                                  </span> :
                                  <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                    ${Math.round(finalData?.discountprice)}
                                  </span>
                              }
                            </Typography>

                          </Stack>
                        </>
                      }



                    </Card>

                    <Card sx={{ marginTop: '20px', display: { xs: 'none', md: 'block' } }}> {/* desktop devices */}

                      {
                        priceHelper(Math.round(finalData?.discountprice), finalData?.finalcost) ? <>

                          <Grid container >

                            <Grid item xs={12} md={6} lg={5}  >

                              <Typography variant="h6" sx={{ color: '#a1a1aa !important', marginBottom: '10px' }} align={isMobileView ? "center" : "left"}>Total Price</Typography>

                            </Grid>

                            <Grid item xs={12} md={6} lg={3.5} >

                              <Typography sx={{ marginBottom: '10px' }}>

                                {
                                  finalData?.comboid == 0 ?
                                    <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                      ${finalData?.packages?.reduce((total, item) => total + (Number(item.packageamount) || 0), 0)}
                                    </span> :
                                    <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                      ${Math.round(finalData?.discountprice)}
                                    </span>
                                }
                              </Typography>

                            </Grid>

                            <Grid item xs={12} md={6} lg={3.5} >


                            </Grid>

                          </Grid>

                        </> : <Grid container sx={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}>

                          <Grid item xs={12} md={6} lg={4}  >

                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                </span> :
                                <Typography variant="h6" sx={{ color: '#a1a1aa !important', marginBottom: '10px' }} align={isMobileView ? "center" : "left"}>Actual Price</Typography>
                            }

                            <Typography variant="h6" sx={{ color: '#a1a1aa !important', marginBottom: '10px' }} align={isMobileView ? "center" : "left"}>{finalData?.comboid == 0 ? "Actual Price" : "Discounted Price"}</Typography>

                          </Grid>

                          <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }} direction={'column'} alignItems={'center'}>

                            <Typography
                              component="span"
                              sx={{ textDecoration: 'line-through', color: 'black' }}
                            >
                              {
                                finalData?.comboid == 0 ?
                                  <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                  </span> :
                                  <span

                                  >
                                    ${finalData?.finalcost}
                                  </span>
                              }

                            </Typography>

                            <Typography sx={{ marginBottom: '10px' }}>

                              {
                                finalData?.comboid == 0 ?
                                  <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                    ${finalData?.packages?.reduce((total, item) => total + (Number(item.packageamount) || 0), 0)}
                                  </span> :
                                  <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                    ${Math.round(finalData?.discountprice)}
                                  </span>
                              }
                            </Typography>

                          </Grid>

                          <Grid item xs={12} md={6} lg={4} >


                          </Grid>

                        </Grid>
                      }



                    </Card>
                  </>
                }






                {/* <Card>
                  {finalData?.packages?.length > 1 && (
                    <Chip
                      icon={<BiSolidOffer />}
                      size="small"
                      label={"Combo Offer"}
                      sx={{ mx: 1, mt: 1, p: 1 }}
                      variant="filled"
                      color="success"
                    />
                  )}

                  {finalData?.packages?.map((item, index) => (
                    <Stack
                      key={index}
                      sx={{ padding: "10px", borderBottom: "1px solid #E0E0E0" }}
                      spacing={2}
                      direction={isMobileView ? "column" : "row"}
                      alignItems={isMobileView ? "flex-start" : "center"}
                    >
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={2} textAlign={isMobileView ? "center" : "left"}>
                          <div
                            style={{
                              border: "1px solid #80808080",
                              borderRadius: "5px",
                              width: isMobileView ? "90%" : "90px",  // Adjust width for mobile
                              height: "55px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: isMobileView ? "0 auto" : "0",  // Center the image on mobile
                            }}
                          >
                            <img
                              src={item?.image}
                              alt="product"
                              style={{
                                objectFit: "contain",
                                maxWidth: "100%",
                                height: "100%",
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={2} textAlign={isMobileView ? "center" : "left"}>
                          <Stack alignItems={isMobileView ? "center" : "flex-start"}>
                            {[{ productType: 4, condition: selectedBTWHour >= 6, comboAmountKey: "combopackageamount1" },
                            { productType: 5, condition: selectedBTWHour, comboAmountKey: "combopackageamount1" },
                            { productType: 3, condition: selectedBTWHour < 12, comboAmountKey: "combopackageamount1" },
                            { productType: 3, condition: selectedBTWHour >= 12, comboAmountKey: "combopackageamount2" }
                            ].map(({ productType, condition, comboAmountKey }) =>
                              item.producttype === productType && finalData?.packages?.length > 1 && condition ? (
                                <Stack justifyContent="center" alignItems="center" key={comboAmountKey}>
                                  <Typography component="span" sx={{ fontSize: "25px" }}>
                                    <b>${item[comboAmountKey]}</b>
                                  </Typography>
                                  <Typography component="span" sx={{ textDecoration: "line-through", color: "black" }}>
                                    ${item.packageamount}
                                  </Typography>
                                </Stack>
                              ) : null
                            )}

                            {(![
                              { productType: 4, condition: selectedBTWHour >= 6 },
                              { productType: 5, condition: selectedBTWHour },
                              { productType: 3, condition: selectedBTWHour < 12 },
                              { productType: 3, condition: selectedBTWHour >= 12 }
                            ].some(({ productType, condition }) => item.producttype === productType && condition)) && (
                                <Typography component="span" sx={{ fontSize: "25px" }}>
                                  <b>${item.packageamount}</b>
                                </Typography>
                              )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Stack alignItems={isMobileView ? "center" : "flex-start"}>
                            <Typography align={isMobileView ? "center" : "left"}>{item.productname}</Typography>
                            {item.producttype !== 5 && (
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <AiTwotoneClockCircle />
                                <Typography>{item.duration} Hrs</Typography>
                              </Stack>
                            )}
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={4} textAlign={isMobileView ? "center" : "right"}>
                          {item?.producttype !== 1 && item?.producttype !== 5 ? (
                            <Chip
                              icon={getChipicon(item) === 1 ? (
                                <TiTick style={{ width: "20px", height: "20px" }} />
                              ) : (
                                <FaRegCalendarAlt style={{ width: "20px", height: "20px" }} />
                              )}
                              label={getChipicon(item) === 1 ? "Slot Picked" : "Pick Slot"}
                              variant="outlined"
                              color={getChipcolor(item)}
                              sx={{
                                padding: "10px 20px",
                                cursor: "pointer",
                                fontWeight: "bold",
                                width: isMobileView ? "90%" : "auto",  // Make button full-width on mobile
                              }}
                              onClick={() => onClickCalander(item)}
                            />
                          ) : (
                            <Typography></Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Stack>
                  ))}


                </Card> */}


                {/* <Card sx={{ m: 1 }}>
                  {finalData?.packages?.length > 1 &&
                    <Chip icon={<BiSolidOffer />} size='small' label={'Combo Offer'} sx={{ mx: 1, mt: 1, p: 1 }} variant="filled" color="success" />
                  }
                  {finalData?.packages?.map((item, index) => (
                    <>

                      <Stack sx={{ px: 2, pt: 1, pb: 1 }} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" >



                        <Stack direction={'row'} spacing={3}>
                          <div style={{
                            // backgroundColor: 'transparent',
                            border: '1px solid #80808080',
                            borderRadius: '5px'

                          }}>

                            <img
                              src={item?.image}
                              alt='car'

                              style={{
                                objectFit: 'contain',
                                width: '90px',
                                height: '55px',
                                // padding: '3px'

                              }}
                            />
                          </div>

                          <Stack >
                            <Stack direction={'column'} justifyContent={'space-between'} >
                              <Typography>
                                {item.productname}
                              </Typography>

                              

                            </Stack>



                            <Typography sx={{ color: 'black' }}>
                              <Stack direction="row" spacing={1} alignItems={'center'}>
                                {[
                                  { productType: 4, condition: selectedBTWHour >= 6, comboAmountKey: 'combopackageamount1' },
                                  { productType: 5, condition: selectedBTWHour, comboAmountKey: 'combopackageamount1' },
                                  { productType: 3, condition: selectedBTWHour < 12, comboAmountKey: 'combopackageamount1' },
                                  { productType: 3, condition: selectedBTWHour >= 12, comboAmountKey: 'combopackageamount2' }
                                ].map(({ productType, condition, comboAmountKey }) => (
                                  item.producttype === productType && finalData?.packages?.length > 1 && condition ? (
                                    <>
                                      <Typography component="span" sx={{
                                        fontSize: '25px !important',
                                        color: '#FFD700',
                                        fontWeight: 'bold',
                                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                        '-webkit-text-stroke': '0.5px black',
                                        textStroke: '0.5px black'
                                      }}>
                                        <b>${item[comboAmountKey]}</b>
                                      </Typography>
                                      <Typography component="span" sx={{ textDecoration: 'line-through', color: 'black' }}>
                                        ${item.packageamount}
                                      </Typography>
                                    </>
                                  ) : null
                                ))}

                                {(![
                                  { productType: 4, condition: selectedBTWHour >= 6 },
                                  { productType: 5, condition: selectedBTWHour },
                                  { productType: 3, condition: selectedBTWHour < 12 },
                                  { productType: 3, condition: selectedBTWHour >= 12 }
                                ].some(({ productType, condition }) => item.producttype === productType && condition)) && (
                                    <Typography component="span">
                                      <b>${item.packageamount}</b>
                                    </Typography>
                                  )}
                              </Stack>
                            </Typography>


                            {
                              item.producttype !== 5 &&
                              <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} spacing={0.5} >
                                <AiTwotoneClockCircle />
                                <Typography>{item.duration}Hrs</Typography>

                              </Stack>
                            }



                          </Stack>
                        </Stack>

                        <Stack spacing={1} alignItems={'center'}>








                          {
                            (item?.producttype !== 1) && (item?.producttype !== 5) ?
                              <Chip icon={getChipicon(item) == 1 ? <TiTick style={{ width: '20px', height: "20px" }} /> : <FaRegCalendarAlt style={{ width: '20px', height: "20px" }} />} label={getChipicon(item) == 1 ? "Slot Picked" : "Pick Slot"} variant="outlined" color={getChipcolor(item)}
                                sx={{ padding: '10px 20px !important', cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => onClickCalander(item)} />

                              :
                              // <Chip icon={<FaRegCalendarAlt />} label="Pick slots" TiTick variant="outlined" color="default"
                              //   sx={{ p: 0.5, cursor: 'not-allowed', userSelect: 'none' }}
                              // />
                              null
                          }

                          {
                            (item?.producttype !== 1) && (item?.producttype !== 5) &&
                            <Typography></Typography>

                          }

                        

                        </Stack>


                      </Stack>

                      <Divider sx={{ mb: 0 }} />
                    </>
                  ))}
                </Card > */}






                {/* <Grid container sx={{ p: 2 }}>

                  <Grid item xs={12} md={6}>

                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card  >
                      <Divider />
                      <Stack sx={{ p: 2 }} spacing={1}>
                        {
                          finalData?.packages?.length > 1 &&
                          <>

                            <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                              <Typography sx={{ color: 'gray !important' }}> Actual Total</Typography>

                              


                              <Typography
                                component="span"
                                sx={{ textDecoration: 'line-through', color: 'black' }}
                              // sx={{
                              //   // textAlign: 'center !important', width: '100%',
                              //   borderRadius: { xs: '10px', md: '' },
                              //   //  boxShadow: { xs: isSticky ? '0px 4px 10px rgba(0, 0, 0, 0.7)' : 'none', md: 'none' },
                              //   //   background: { xs: isSticky ? '#fdba74' : 'white', md: 'white', lg: 'white' } 
                              // }}
                              >


                                {
                                  finalData?.comboid == 0 ?
                                    <span style={{ fontWeight: "700", fontSize: "30px", color: "black" }}>

                                    </span> :
                                    <span
                                    // style={{
                                    //   textDecoration: "line-through",
                                    //   fontSize: "20px",
                                    //   color: 'gray'
                                    // }}
                                    >
                                      ${finalData?.finalcost}
                                    </span>
                                }

                              </Typography>

                            </Stack>

                            
                          </>
                        }
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography sx={{ color: 'gray !important' }} > Discounted Total</Typography>

                          <Typography>

                            {
                              finalData?.comboid == 0 ?
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${finalData?.packages?.reduce((total, item) => total + (Number(item.packageamount) || 0), 0)}
                                </span> :
                                <span style={{ fontWeight: "700", fontSize: "25px", color: "black" }}>
                                  ${Math.round(finalData?.discountprice)}
                                </span>
                            }
                          </Typography>

                        </Stack>
                      </Stack>

                    </Card>
                  </Grid>
                </Grid> */}


              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ width: '100%', mt: 0 }}>
                <Card sx={{ m: 1, p: 1.3 }}>


                  <Stepper activeStep={activeStep} orientation={isSmallScreen ? 'vertical' : 'horizontal'}>
                    {steps.map((label, index) => (
                      <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                </Card>
                {/* <Divider sx={{ mt: 1 }} /> */}



                {activeStep === 0 && (
                  <RegisterForm
                    handleNext={handleNext}
                    handleBack={handleBack}
                    formData={formData}
                    setFormData={setFormData}
                    setActiveStep={setActiveStep}
                    coursedetails={coursedetails}
                    setRegisterStatus={setRegisterStatus}
                    finalData={finalData}

                  />
                )}

                {activeStep === 1 && (
                  <DocumentSection
                    handleNext={handleNext}
                    handleBack={handleBack}

                    answers={answers}
                    setAnswers={setAnswers}

                    coursedetails={coursedetails}
                    setCoursedetails={setCoursedetails}

                    docInfo={docInfo}
                    setDocInfo={setDocInfo}
                  />
                )}

                {activeStep === 2 && (
                  <PaymentPage
                    handleBack={handleBack}
                    onClickSubmit={onClickSubmit}
                    setActiveStep={setActiveStep}
                    coursedetails={coursedetails}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                    formData={formData}
                    setFormData={setFormData}
                    selected={selected}
                    setSelected={setSelected}
                  />

                )}
              </Box>
            </Grid>
          </Grid>




          <Calendar
            open={open}
            setOpen={setOpen}
            selectedCourseInfo={selectedCourseInfo}
            coursedetails={coursedetails}
            setCoursedetails={setCoursedetails}
            zones={zones}
            setZones={setZones}
          />

        </DialogContent>


        {/* </Dialog> */}

      </Drawer>
    </>
  );

};



export default NewUserRegister;